import { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { EmbeddedLayout, LbLogoLoading, useToken, useClient, lbUrlNavigator, useOrganization } from "@lb/frontend";
import {
  jsonCargo,
  authClientUrls,
  USER_TYPE_MASTER,
  contractsClientUrls,
  templatesClientUrls,
  maintenanceModeClientUrl,
  ORGANIZATION_STATUS_OPTIONS,
} from "@lb/utils";

const Matrix = lazy(() => import("./pages/Matrix"));
const MatrixCreateEdit = lazy(() => import("./pages/Matrix/CreateEdit"));

const Contracts = lazy(() => import("./pages/contracts"));
const CreateContract = lazy(() => import("./pages/contracts/Create"));
const EditContract = lazy(() => import("./pages/contracts/Edit"));
const ContractSummary = lazy(() => import("./pages/contracts/Summary"));
const RejectContract = lazy(() => import("./pages/contracts/Summary/Reject"));

const Templates = lazy(() => import("./pages/Templates"));

const AppLayout = lazy(() => {
  if (useToken.getState().token) {
    if (useClient.getState()?.client?.userType === USER_TYPE_MASTER) {
      window.location.replace(process.env.REACT_APP_MASTER_URL);
    } else {
      if (useOrganization.getState()?.organization?.status === ORGANIZATION_STATUS_OPTIONS.key.MAINTENANCE) {
        window.location.replace(lbUrlNavigator(maintenanceModeClientUrl.base, "IAM") + `#${jsonCargo.store({ from: window.location.href })}`);
      }
      return import("@lb/frontend")?.then?.((module) => ({
        default: module.LbLayout,
      }));
    }
  } else {
    window.location.replace(lbUrlNavigator(authClientUrls.login.base, "AUTH") + `#${jsonCargo.store({ from: window.location.href })}`);
  }

  return new Promise(() => {});
});

const router = createBrowserRouter([
  {
    path: "embedded",
    element: <EmbeddedLayout />,
    children: [
      {
        path: contractsClientUrls.slug,
        element: <Contracts isEmbedded={true} />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<LbLogoLoading />}>
        <AppLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={contractsClientUrls.slug} replace />,
      },
      {
        path: contractsClientUrls.slug,
        children: [
          {
            index: true,
            element: <Contracts />,
          },
          {
            path: contractsClientUrls.create.slug,
            element: <CreateContract />,
          },
          {
            path: contractsClientUrls.edit.slug,
            element: <EditContract />,
          },
          {
            path: contractsClientUrls.summary.slug,
            element: <ContractSummary />,
            children: [
              {
                path: contractsClientUrls.summary.review.slug,
                element: <RejectContract />,
              },
            ],
          },
        ],
      },
      {
        path: templatesClientUrls.slug,
        element: <Templates />,
      },
      { path: "matrix", element: <Navigate replace to={"/matrix/signtory"} /> },
      {
        path: contractsClientUrls.matrix.slug,
        children: [
          { index: true, element: <Matrix /> },
          {
            path: contractsClientUrls.matrix.create.slug,
            element: <MatrixCreateEdit />,
          },
          {
            path: contractsClientUrls.matrix.update.slug,
            element: <MatrixCreateEdit />,
          },
        ],
      },
    ],
  },
]);

export default router;
