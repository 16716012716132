import { LbAppWrapper, LbLogoLoading } from "@lb/frontend";
import { RouterProvider } from "react-router-dom";
import router from "./router";

export default function App() {
  return (
    <LbAppWrapper>
      <RouterProvider {...{ router, fallbackElement: <LbLogoLoading /> }} />
    </LbAppWrapper>
  );
}
